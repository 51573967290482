import React, { useState } from 'react';
import { IonCardTitle, IonCardHeader, IonCard, 
  IonContent, IonPage,  
  IonList, IonButton , 
  useIonAlert} from '@ionic/react';

import { useForm } from "react-hook-form";

import CustomInput from '../components/InputContainer'

interface ProductErrors {
  title: string,
  min: string,
}

export interface Product {
  id: number | undefined,
  title: string,
  store_id: number,
  min: number,
  active: boolean,
}

const ProductPage: React.FC<{
  saveProduct: any;
  product: Product | undefined;
  handleDismiss: any;
  store_id: number,
  setProductId: any,
}> = ({ saveProduct, product, handleDismiss, store_id, setProductId}) => {
  const [present] = useIonAlert();
  
  const defaultValues:Product = product || {
    id:  undefined,
    title: '',
    store_id: store_id,
    min: 0,
    active: true,  
  };
  
  const [formErrors, setFormErrors] = useState<ProductErrors>();

  const { register, handleSubmit, formState: { errors } } = useForm({defaultValues});

  const onSubmit = async (data: Product) => {
    //SaveClient
    
    const responce = await saveProduct(data);

    console.log(responce)
    if(responce?.status == 200){
      handleDismiss();
    }else{
      let $message = responce.message ? responce.message : 'Error saving, please check inputs and try again';
      present($message, [{ text: 'Ok' }]);
      setFormErrors(responce.errors);
    }
  };

  return (
    <IonPage>
      
      <IonContent className="page-bg">
      
      <form onSubmit={handleSubmit(onSubmit)}>
      <IonCard>
      <IonCardHeader>
        <IonCardTitle>Product</IonCardTitle>
      </IonCardHeader>
      <input type="hidden" {...register("id")} />
      <input type="hidden" {...register("active")} />
      <input type="hidden" {...register("store_id")} />
        <IonList>
        
          {/* title */}
          <CustomInput type="text" 
            specForm={formErrors?.title} 
            inputText="Title" 
            error={errors.title} 
            register={register("title", { required: true })} 
            placeholder={undefined} 
            list={undefined} 
            itemType={undefined}></CustomInput>

          {/* min */}
          <CustomInput type="text" 
            specForm={formErrors?.min} 
            inputText="Minimum" 
            error={errors.min} 
            register={register("min", { required: true })} 
            placeholder={undefined} 
            list={undefined} 
            itemType={undefined}></CustomInput>

        </IonList>

        </IonCard>
        <IonButton expand="block" type="submit" className="ion-margin-top ion-padding-horizontal">
              Save
            </IonButton>
        </form>

        <IonButton expand="block" color="primary" className="ion-margin-top ion-padding-horizontal" onClick={()=>handleDismiss()}>
              Cancel
        </IonButton>
        
      </IonContent>
    </IonPage>
  );
};

export default ProductPage;
