import React, { useState, useRef, useEffect  } from 'react';
import { IonCardTitle, IonCardHeader, IonCard, useIonAlert,
   IonContent, IonPage, IonButton, useIonModal,useIonLoading, IonHeader, IonCardSubtitle, IonList, IonItem  } from '@ionic/react';

import {Api} from '../services/api';

import { RouteComponentProps } from 'react-router';

import PinInput from 'react-pin-input';
import HeaderContainer from '../components/HeaderContainer';

const Body: React.FC<{api: Api; onDismiss: any;
}> = ({api, onDismiss}) => {
  const [presentAlert] = useIonAlert();
  const [oldPin, setOldPin] = useState<string>('');
  const [newPin, setNewPin] = useState<string>('');
  const refNew = useRef<PinInput>(null);
  const refConfirm = useRef<PinInput>(null);
  const refOld = useRef<PinInput>(null);
  const [isLoading, loadingComplete] = useIonLoading();

  useEffect(() => {
    if(!api.client?.haspin){
      setOldPin('notneeded');
    }
  },[]);

  return (
    <IonPage>
      
    <IonContent className="page-bg">
    <IonHeader>
        <HeaderContainer title="Enter Pin" />
    </IonHeader>
    <IonCard style={{textAlign: 'center'}}>
    <IonCardHeader>
      <IonCardTitle>{oldPin ? newPin ? 'Confirm New PIN' :'Enter New PIN'  : 'Enter Current PIN'}</IonCardTitle>
    </IonCardHeader>
    {!oldPin && !newPin &&
    <PinInput 
            ref={refOld}
            length={4} 
            initialValue=""
            secret 
            type="numeric" 
            inputMode="number"
            focus={true}
            style={{padding: '10px'}}  
            inputStyle={{borderColor: 'red'}}
            inputFocusStyle={{borderColor: 'blue'}}
            onComplete={(value, index) => {
              //TODO: Check old pin and if good
              if(value === api.pin){
                setOldPin(value);
                refNew.current && refNew.current.focus();
              }else{
                presentAlert('Invalid PIN', [{ text: 'Ok' }]);
                refOld.current && refOld.current.clear();
              }
            }}
            autoSelect={true}
            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          />}
{oldPin && !newPin &&
    <PinInput 
          ref={refNew}
            length={4} 
            initialValue=""
            secret 
            type="numeric" 
            inputMode="number"
            style={{padding: '10px'}}  
            inputStyle={{borderColor: 'red'}}
            inputFocusStyle={{borderColor: 'blue'}}
            onComplete={(value, index) => {
              setNewPin(value);
              refConfirm.current && refConfirm.current.focus();
            }}
            autoSelect={true}
            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          />}

{oldPin && newPin &&
    <PinInput 
            ref={refConfirm}
            length={4} 
            initialValue=""
            secret 
            type="numeric" 
            inputMode="number"
            style={{padding: '10px'}}  
            inputStyle={{borderColor: 'red'}}
            inputFocusStyle={{borderColor: 'blue'}}
            onComplete={ async (value, index) => {
              if(value == newPin){
                //isLoading();
                const responce = await api.saveClient({...api.client, pin: value});
                //loadingComplete();
                console.log(responce)
                if(responce?.status == 200){
                  presentAlert('Success! New pin set.', [{ text: 'Ok' }]);
                }else{
                  presentAlert('Error saving pin, please try again.', [{ text: 'Ok' }]);
                }
                
                onDismiss();
              }else{
                presentAlert('Confirm pin does not match.', [{ text: 'Ok' }]);
                refConfirm.current !== null && refConfirm.current.clear();
              }
            }}
            autoSelect={true}
            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          />}
  
    <IonButton expand="block" onClick={() => onDismiss()}>
      Cancel
    </IonButton>
    </IonCard>
    </IonContent>
    </IonPage>
)};

interface SettingsPageProps extends RouteComponentProps { 
  api: Api
}

const SettingsPage: React.FC<SettingsPageProps> = ({ history, match, api }) => {
  const refPin = useRef<PinInput>(null);
  const [isLoading, loadingComplete] = useIonLoading();
  const [presentAlert] = useIonAlert();
  const [present, dismiss] = useIonModal(Body, {
    api: api,
    onDismiss: () => {dismiss();},
  });

  return (
    <IonPage>
      <IonContent className="page-bg">
      <IonHeader>
        <HeaderContainer title="Settings" />
      </IonHeader>
      
      {api.unlocked ? 
      <IonCard style={{textAlign: 'center'}}>
      <IonCardHeader>
        <IonCardSubtitle>Select a Setting Below</IonCardSubtitle>
      </IonCardHeader>
        <IonButton expand="block" fill="clear" className="ion-padding-horizontal" color="tertiary" routerLink="/client">Contact Details</IonButton>
    
        <IonButton expand="block" fill="clear" className="ion-padding-horizontal" color="tertiary" routerLink="/stores">Manage Stores</IonButton>

        <IonButton expand="block" fill="clear" className="ion-padding-horizontal" color="tertiary"  routerLink="/products">Manage Products</IonButton>

        <IonButton expand="block" fill="clear" className="ion-padding-horizontal" color="tertiary"  routerLink="/templates">Manage Templates</IonButton>


        <IonButton expand="block" fill="clear" className="ion-padding-horizontal ion-margin-bottom" color="tertiary" onClick={()=>present()}>{api.client?.haspin ? "Change Pin" : "Set Pin"}</IonButton>
      {api.client?.haspin &&
      <IonButton expand="block" fill="clear" className="ion-padding-horizontal" onClick={()=> api.lock()}>Lock</IonButton>}

      </IonCard>: 
      
      <IonCard>
      <IonCardHeader>
        <IonCardTitle>Unlock</IonCardTitle>
      </IonCardHeader>
      <PinInput 
            ref={refPin}
            length={4} 
            initialValue=""
            secret 
            type="numeric" 
            inputMode="number"
            style={{padding: '10px'}}  
            inputStyle={{borderColor: 'red'}}
            inputFocusStyle={{borderColor: 'blue'}}
            onComplete={ async (value, index) => {
              
                //isLoading();
                const responce = await api.unlock(value);
                //loadingComplete();
                if(!responce){
                  presentAlert('Incorrect, please try again.', [{ text: 'Ok' }]);
                  refPin.current !== null && refPin.current.clear();
                }
                
              
            }}
            autoSelect={true}
            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          />
      </IonCard>
      }
      </IonContent>

    </IonPage>
  );
};

export default SettingsPage;
