import {
    Camera,
    CameraResultType,
    CameraSource,
  } from "@capacitor/camera";
  
  export interface UserPhoto {
      filepath: string;
      webviewPath?: string;
  }
  
  export function usePhoto() {
  
      const takePhoto = async () => {
          try{
              const cameraPhoto = await Camera.getPhoto({
                  resultType: CameraResultType.Uri,
                  source: CameraSource.Camera,
                  quality: 100,
              });
  
              const base64Data = await base64FromPath(cameraPhoto.webPath!);
  
              return base64Data;      
          }catch (e){
              console.log('no photo');
          }
      };
  
      return {
          takePhoto,
      };
  }
  
  export async function base64FromPath(path: string): Promise<string> {
      const response = await fetch(path);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
          if (typeof reader.result === 'string') {
            resolve(reader.result);
          } else {
            reject('method did not return a string')
          }
        };
        reader.readAsDataURL(blob);
      });
  }
  