import React, { useEffect, useState } from 'react';
import { IonCardTitle, IonBadge, IonCard, 
  useIonModal, IonContent, IonPage,  
  IonItem, IonLabel, IonList, IonButton ,IonListHeader, useIonLoading,
  useIonAlert, 
  IonRefresher,
  IonRefresherContent,
  IonTab,
  IonToolbar,
  IonTitle,
  IonIcon,
  IonGrid,
  IonRow,
  IonButtons,
  IonCardHeader,
  IonCardContent,
  IonText,
  IonInput,
	IonTextarea,
	useIonToast,
	IonFab,
	IonFabButton,
	IonCardSubtitle,
	IonCol,
	IonImg,
	IonDatetime,
	IonSelect,
	IonSelectOption,
	IonSpinner,
	IonItemDivider} from '@ionic/react';
  import { RefresherEventDetail } from '@ionic/core';

import {Api} from '../services/api';

import Task from '../modal/Task';
import { alertCircleOutline, camera, cameraOutline, chevronBackOutline, reload, reloadCircleOutline } from 'ionicons/icons';
import { time } from 'console';
import { min } from 'moment';
import { Camera, CapturedImage, Useage, useDateChecker } from '../camera';
import { Product } from './ProductPage';
import { parseISO } from 'date-fns';
import { format } from 'path';
import { useForm } from 'react-hook-form';
import { usePhoto, UserPhoto } from '../hooks/usePhoto';
import Moment from 'react-moment';
import { present } from '@ionic/core/dist/types/utils/overlays';
import { CustomInput } from '../components/InputContainer';
import da from 'date-fns/esm/locale/da/index.js';

interface TaskErrors {
	operator: string,
	photo: string,
}

interface TaskPageProps {
	api: Api,
	task: Task,
	updateTask: () => void,
	saveTask: any,
	dismissTask: () => void,
	addImage:any;
  images:CapturedImage[];
  onDeleteImage: (image:CapturedImage) => boolean;
  onSaveImage: (image:CapturedImage) => Promise<CapturedImage>;

}

const TaskPage: React.FC<TaskPageProps> = ({ api, task, updateTask, saveTask, dismissTask, addImage, images, onDeleteImage, onSaveImage}) => {

	const [date, setDate] = useState<string>(task.end); 
	const [presentToast, dismissToast] = useIonToast();
	const [taskPhoto, setTaskPhoto] = useState<string | undefined>(undefined); 
	const {takePhoto} = usePhoto();
	  
	const [formErrors, setFormErrors] = useState<TaskErrors>();
	const [present] = useIonAlert();
	
	const { register, handleSubmit, formState: { errors } } = useForm({mode: "onBlur"});

	const takeImage = async () => {
		const image = await takePhoto();

		if(image){
			setTaskPhoto(image); 
		}
	}

	const checkPhoto = () => {
		let photoTaken = false;
		if (taskPhoto === undefined){
			return false;
		} else {
			return true;
		}
		return photoTaken;
	}

	const checkDate = () => {
		const dateObj = new Date(date); 
		const dateString: string = dateObj.toLocaleDateString();
		const hours: number = dateObj.getHours();
		const minutes: number = dateObj.getMinutes();

		const currentDate = new Date().toLocaleDateString();

		if (dateString === currentDate){
			presentToast({message: "This task is due today!", duration: 5000, position: 'top'});
			return <IonBadge color="danger">Today at {hours}:{minutes}</IonBadge>;
		} else {
			return <IonBadge color="light">{hours}:{minutes} on {dateString}</IonBadge>;
		}
	}

	const onSubmit = async (data: Task) => {
		if (taskPhoto !== undefined){
			data.photo = taskPhoto;
		}

		const updateTask: Task = task;
		updateTask.operator = data.operator;
		updateTask.photo = data.photo;
		
		api.saveTask(updateTask)
		dismissTask(); 

		// console.log(response)
		// if(response?.status == 200){
		// 	dismissTask();
		// }else{
		// let $message = response.message ? response.message : 'Error saving, please check inputs and try again';
		// 	present($message, [{ text: 'Ok' }]);
		// 	setFormErrors(response.errors);
		// }

	}

	return (
			<IonPage>
			<IonToolbar>
					<IonTitle>Task Item: {task.task_template.title}</IonTitle>
					<IonButtons slot="start">
							<IonButton fill="clear" onClick={dismissTask}><IonIcon icon={chevronBackOutline}/> Back</IonButton>
					</IonButtons>
			</IonToolbar>
			<IonContent className="page-bg">
			<form onSubmit={handleSubmit(onSubmit)}>
			<IonCard>
				<IonCardHeader>
				<IonCardTitle>Task Information</IonCardTitle>
				</IonCardHeader>
				<IonCardContent>
						<IonList>
								<IonItem>
									<IonLabel>Title</IonLabel>
									<IonLabel>{task.task_template.title}</IonLabel>
								</IonItem>
								<IonItem hidden={task.notes === null ? true : false}>
										<IonLabel>Notes</IonLabel>
										<IonText>{task.notes}</IonText>
								</IonItem>
								<IonItem>
										<IonLabel>Time Due</IonLabel>
										{checkDate()}
								</IonItem>
						</IonList>
				</IonCardContent>
			</IonCard>
			<IonCard>
				<IonCardHeader>
					<IonCardTitle>Task Completion </IonCardTitle>
				</IonCardHeader>
				<IonCardContent>
					
					<IonList>
						<IonItem color={errors.operator ? "danger" : ''}>
							<IonLabel><b>Operator</b></IonLabel>
							<IonInput value={task.operator} placeholder="Operator Number" type="number" {...register("operator", {required: true, minLength: 4, maxLength: 10})}/>
						</IonItem>
						{errors.operator ? 
							<IonItem>
								<IonLabel>Error</IonLabel>
							</IonItem> :
							<></>}
						{task.task_template.photo_required ? 
						<IonItem>
							<IonLabel>Photo Required</IonLabel>
							<IonButton slot="end" fill="clear" disabled={checkPhoto()} onClick={takeImage}><IonIcon icon={cameraOutline}></IonIcon></IonButton>
						</IonItem>
						:
						<></>
						}
						{task.photo !== undefined ? 
						<IonItem>
							<IonImg src={task.photo} />
							<IonButton fill="clear" onClick={takeImage} color="danger"><IonIcon icon={reloadCircleOutline}/></IonButton>
						</IonItem> :
						taskPhoto !== undefined ? 
						<IonItem>
							<IonImg src={taskPhoto} />
							<IonButton fill="clear" onClick={takeImage} color="danger"><IonIcon icon={reloadCircleOutline}/></IonButton>
						</IonItem> : <></>
						}
					</IonList>
					
					</IonCardContent>
				</IonCard>
				<IonButton color="primary" type="submit" className="ion-margin-horizontal" disabled={task.task_template.photo_required ? task.photo === undefined ? taskPhoto !== undefined ? false : true : true : false} expand="block" >Task Completed</IonButton>
				
				</form>
			</IonContent>
			</IonPage>
	);
};

export default TaskPage;
