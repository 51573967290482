
export interface TaskTemplate {
    id: number | undefined,
    store_id: number | undefined,
    title: string,
    notes: string,
    importance: number,
    next_start: string,
    frequency: string,
    unit: number,
    first_warning: number,
    second_warning: number,
    third_warning: number,
    expire: number,
    photo_required: boolean,
    active: boolean,
}

export const DefaultTemplateValues: TaskTemplate ={
    id: undefined,
    store_id: undefined,
    title: '',
    notes: '',
    importance: 3,
    next_start: JSON.stringify(new Date()),
    frequency: '',
    unit: 0,
    first_warning: 0,
    second_warning: 0,
    third_warning: 0,
    expire: 0,
    photo_required: false,
    active: true


}

export default interface Task {
    id: number,
    store_id: number,
    task_template_id: number,
    task_template: TaskTemplate,
    start: string,
    end: string,
    actioned: string,
    photo: string, //Check if okay
    notes: string,
    operator: string,
    active: boolean,
    created?: string,
    modified?: string
}