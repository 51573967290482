import { IonContent, IonButton, IonHeader, IonPage, IonTitle, IonToolbar,IonInput,IonCard, IonCardHeader,IonCardContent, useIonAlert, IonCardSubtitle} from '@ionic/react';
import { useState} from 'react';
import { RouteComponentProps } from 'react-router';

import validator from 'validator';
import HeaderContainer from '../components/HeaderContainer';

/*
Status Codes - https://restfulapi.net/http-status-codes/
1xx: Informational – Communicates transfer protocol-level information.
2xx: Success – Indicates that the client’s request was accepted successfully.
3xx: Redirection – Indicates that the client must take some additional action in order to complete their request.
4xx: Client Error – This category of error status codes points the finger at clients.
5xx: Server Error – The server takes responsibility for these error status codes.

Looking for 200 OK

401 Unauthorized - Not signed in
403 Forbidden - Is singed in but no access to what is requested
404 Not Found
408 Request Timeout

*/

interface LoginPageProps extends RouteComponentProps { 
  sendEmail: any,
  sendCode: any
}
const LoginPage: React.FC<LoginPageProps> = ({ match, sendEmail, sendCode }) => {
  const [present] = useIonAlert();

  const [email, setEmail] = useState("");
  const [sent, setSent] = useState(false);
  const [code, setCode] = useState("");

  const onSendEmail = async () => {

    if(!validator.isEmail(email)){
      present('Please enter an email', [{ text: 'Ok' }]);
      return;
    }

    const responce = await sendEmail(email);

    if(responce){
      setSent(true);
    }else{
      present('Issue sending email, please try again.', [{ text: 'Ok' }]);
    }

  };

  const onSubmit = async () => {

    if(!code){
      present('Please enter the code', [{ text: 'Ok' }]);
      return;
    }

    const responce = await sendCode(code);
    if(!responce){
      present('Code not valid, please try again.', [{ text: 'Ok' }]);
    }

  };


  return (
    <IonPage>
      <IonContent fullscreen>
          <HeaderContainer title="Login" />
        <IonCard>
          <IonCardHeader> <IonCardSubtitle>Please enter your email below to being the sign in process.</IonCardSubtitle>
          
          </IonCardHeader>
          <IonCardContent>
      <>
      {sent === false ? (
        <>
      <IonInput value={email} type="email" placeholder="Enter Email" onIonChange={e => setEmail(e.detail.value!)} clearInput></IonInput>
      <IonButton expand="block" onClick={()=> onSendEmail()}>
          Next
      </IonButton>
  </>
      ):(
        <>
        <h3>Code sent to email: {email}. Enter code below.</h3>
      <IonInput value={code} type="text" placeholder="Enter Code" onIonChange={e => setCode(e.detail.value!)} clearInput></IonInput>
      <IonButton expand="block" onClick={()=> onSubmit()}>
        Next
    </IonButton>
    <IonButton expand="block"  onClick={()=> setSent(false)}>
        Code didn't arive, try again.
    </IonButton>
        </>
      )}
      </>
</IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default LoginPage;
