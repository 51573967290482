import React, { useState, useEffect } from 'react';
import { IonCardTitle, IonCardHeader, IonCard, 
  IonBadge, useIonModal, IonContent, IonPage, IonInput, 
  IonItem, IonLabel, IonList, IonButton ,IonListHeader, 
  IonSelect, IonSelectOption,useIonAlert ,IonIcon, IonCardSubtitle} from '@ionic/react';

import { useForm } from "react-hook-form";
import { RouteComponentProps } from 'react-router';
import {Store} from './StorePage';
import CustomInput from '../components/InputContainer'
import HeaderContainer from '../components/HeaderContainer';

export interface Client {
  id: number | undefined,
  name: string,
  haspin: boolean,
  business_name: string,
  pin: string,
  email: string,
  address: string,
  stores: Store[] | undefined,
}

interface ClientErrors {
  name: any,
  business_name: any,
  email: any,
  address: any
}


interface ClientPageProps extends RouteComponentProps { 
  saveClient: any,
  onLogout: any,
  client: Client | undefined
}

const ClientPage: React.FC<ClientPageProps> = ({ history, match, saveClient, client, onLogout }) => {
  const [presentAlert] = useIonAlert();
  
  const defaultValues:Client = client || {
    name: "", 
    business_name:"", 
    email:"", 
    pin: "",
    address:"",
    haspin: false,
    id:undefined,
    stores:undefined,
  };
  
  const [formErrors, setFormErrors] = useState<ClientErrors>();

  const { register, handleSubmit, formState: { errors } } = useForm({defaultValues});

  const onSubmit = async (data: Client) => {
    //SaveClient
    
    const responce = await saveClient(data);

    console.log(responce)
    if(responce?.status == 200){
      history.goBack();
    }else{
      let $message = responce.message ? responce.message : 'Error saving, please check inputs and try again';
      presentAlert($message, [{ text: 'Ok' }]);
      setFormErrors(responce.errors);
    }
  };

  const logout = async () => {

    const responce = await onLogout();

    if(responce){
      history.push('/')
    }else{
      let $message = responce?.message ? responce.message : 'Issue logging out, please try again.';
      presentAlert($message, [{ text: 'Ok' }]);
    }
  };

  return (
    <IonPage>
      
      <IonContent className="page-bg">
      <HeaderContainer title="Client Settings" />
      
      <form onSubmit={handleSubmit(onSubmit)}>
      <IonCard>
      <IonCardHeader>
            <IonCardSubtitle>Input your information below</IonCardSubtitle>
          </IonCardHeader>
      <input type="hidden" {...register("id")} />
        <IonList>
        
          {/* Name */}
          <CustomInput type="text" specForm={formErrors?.name} 
            inputText="Name" 
            error={errors.name} 
            register={register("name", { required: true })} 
            placeholder={undefined} 
            list={undefined} 
            itemType={undefined}></CustomInput>

          {/* Business Name */}
          <CustomInput type="text" 
            specForm={formErrors?.business_name} 
            inputText="Business Name" 
            error={errors.business_name} 
            register={register("business_name", { required: true })} 
            placeholder={undefined} 
            list={undefined} 
            itemType={undefined}></CustomInput>

          {/* Email */}
          <CustomInput type="email" 
            specForm={formErrors?.email} 
            inputText="Email" 
            error={errors.email} 
            register={register("email", { required: true })} 
            placeholder={undefined} 
            list={undefined} 
            itemType={undefined}></CustomInput>

          {/* Address */}
          <CustomInput type="text" 
            specForm={formErrors?.address} 
            inputText="Address" 
            error={errors.address} 
            register={register("address", { required: true })} 
            placeholder={undefined} 
            list={undefined} 
            itemType={undefined}></CustomInput>

        </IonList>

        </IonCard>
        <IonButton expand="block" type="submit" className="ion-margin-top ion-padding-horizontal">
              Save
            </IonButton>
        </form>
        
        <IonButton expand="block" className="ion-padding-horizontal"
        onClick={() =>
          presentAlert({
            cssClass: 'my-css',
            header: 'Alert',
            message: 'Are you sure you would like to log out?',
            buttons: [
              'Cancel',
              { text: 'Ok', handler: () => logout() },
            ],
            onDidDismiss: () => console.log('did dismiss'),
          })
        }>Logout</IonButton>
      </IonContent>
    </IonPage>
  );
};

export default ClientPage;
