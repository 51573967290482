import { Redirect, Route } from 'react-router-dom';
import { useEffect } from "react";
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  useIonLoading
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { cameraOutline, cog, alertCircleOutline, listOutline, documentTextOutline} from 'ionicons/icons';
import ScanPage from './pages/Scan';
import StatusPage from './pages/Status';

import ClientPage from './pages/ClientPage';
import LoginPage from './pages/LoginPage';
import ProductsPage from './pages/ProductsPage';
import SettingsPage from './pages/SettingsPage';
import StoresPage from './pages/StoresPage';
import TasksPage from './pages/TasksPage';
import TaskTemplatesPage from './pages/TaskTemplatesPage';


import AgreePage from './pages/AgreePage';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import useServerStatus, {Api} from "./services/api"

const App: React.FC = () => {
  
  const api: Api = useServerStatus();
  const [isLoading, loadingComplete] = useIonLoading();

  useEffect(() => {
    
    if(api.loading){
      isLoading();
    }else{
      loadingComplete();
    }

  },[api.loading]);
  
  return (
  <IonApp>
    <IonReactRouter>
    {api.authed && api.products ? 
      <IonTabs>
        <IonRouterOutlet>
        <Route exact path="/status" render={(props) => 
          { 
            //If client is new 
            if(api.client !== undefined && api.client.name === undefined ){
                return (
                  <ClientPage {...props} 
                      client={api.client} 
                      onLogout={api.onLogout}
                      saveClient={api.saveClient}
                      />
                );
            }

            //If client all setup
            return api.client !== undefined && (
              <StatusPage 
              api={api}
              />);
        }
          }/>

            
          <Route exact 
            path="/templates" 
            render={(props) => api.client !== undefined ? 
              <TaskTemplatesPage 
                api={api} 
                taskTemplates={api.taskTemplates}
                /> : <>Loading...</> }/>
          


          <Route exact path="/scan">
            <ScanPage 
              api={api}
              addImage={api.addImage} 
              images={api.images} 
              products={api.products}
              onDeleteImage={api.onDeleteImage} 
              onSaveImage={api.onSaveImage}
              />
          </Route>
          <Route exact 
            path="/products"  
            render={(props) => api.client !== undefined?
              <ProductsPage {...props} 
                      products={api.products}
                      saveProduct={api.saveProduct} 
                      store_id={api.store_id}
                      api={api}
                      />: <>Loading ...</>} />

          <Route exact 
            path="/client"  
            render={(props) => api.client !== undefined?
              <ClientPage {...props} 
                      client={api.client} 
                      onLogout={api.onLogout}
                      saveClient={api.saveClient}
                      />: <>Loading ...</>} />

          <Route exact 
            path="/tasks"  
            render={(props) => api.tasks !== undefined?
              <TasksPage {...props} 
                      api={api}
                      
                      />: <>Loading ...</>} />

          <Route exact 
            path="/stores"  
            render={(props) => api.client !== undefined?
              <StoresPage {...props} 
                      client={api.client} 
                      saveStore={api.saveStore} 
                      store_id={api.store_id}
                      setStoreId={api.onChangeStore}
                      />: <>Loading ...</>} />

        <Route exact 
            path="/settings"  
            render={(props) => api.client !== undefined?
              <SettingsPage {...props} api={api}
                      />: <>Loading ...</>} />

          <Route exact path="/">
            <Redirect to="/status" />
          </Route>
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          
          
          <IonTabButton tab="status" href="/status">
            <IonIcon icon={alertCircleOutline} />
            <IonLabel>Status</IonLabel>
          </IonTabButton>
          <IonTabButton tab="tasks" href="/tasks">
            <IonIcon icon={listOutline}  />
            <IonLabel>Tasks</IonLabel>
          </IonTabButton>
          <IonTabButton tab="scan" href="/scan">
            <IonIcon icon={cameraOutline}  />
            <IonLabel>Scan</IonLabel>
          </IonTabButton>
          <IonTabButton tab="settings" href="/settings">
            <IonIcon icon={cog} />
            <IonLabel>Settings</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
      :
      <IonRouterOutlet>
        
        <Route exact path="/home" render={props => { return api.agree ? <LoginPage {...props} 
            sendCode={api.sendCode} sendEmail={api.sendEmail} /> :  <AgreePage {...props} clearAgree={api.clearAgree} />;} }/>

        <Route exact path="/">
          <Redirect to="/home" />
        </Route>

      </IonRouterOutlet>
      }
    </IonReactRouter>
  </IonApp>
)};

export default App;
