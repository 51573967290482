import React, { useEffect, useState } from 'react';
import { IonCardTitle, IonBadge, IonCard, 
  useIonModal, IonContent, IonPage,  
  IonItem, IonLabel, IonList, IonButton ,IonListHeader, useIonLoading,
  useIonAlert, 
  IonRefresher,
  IonRefresherContent,
  IonTab,
  IonToolbar,
  IonTitle,
  IonIcon,
  IonGrid,
  IonRow,
  IonButtons,
  IonCardHeader,
  IonCardContent,
  IonText,
  IonInput,
	IonTextarea,
	useIonToast,
	IonFab,
	IonFabButton,
	IonCardSubtitle,
	IonCol,
	IonImg,
	IonDatetime,
	IonSelect,
	IonSelectOption,
	IonSpinner,
	IonItemDivider,
  IonPopover} from '@ionic/react';
  import { RefresherEventDetail } from '@ionic/core';

import { TaskTemplate } from '../modal/Task';

import {Api} from '../services/api';
import { chevronBackOutline } from 'ionicons/icons';
import { useForm } from 'react-hook-form';
import TaskPriority from '../components/PriorityComponent';
import { dismiss } from '@ionic/core/dist/types/utils/overlays';


interface TaskTemplatePageProps {
	api: Api,
	taskTemplate: TaskTemplate,
  dismissTaskTemplate: any
}

interface TaskTemplateErrors {
	title: string
}

const TaskTemplatePage: React.FC<TaskTemplatePageProps> = ({ api, taskTemplate, dismissTaskTemplate }) => {

  const nextStart = new Date(taskTemplate.next_start); 

  const defaultValues: TaskTemplate = taskTemplate; 

  const { register, getValues, setValue, handleSubmit, formState: { errors } } = useForm({mode: "onBlur", defaultValues});

  const [title, setTitle] = useState<string>(taskTemplate.title);

  const [editMode, setEditMode] = useState<boolean>(taskTemplate.id === undefined ? true : false);
  const [dismissAlert, setDismissAlert] = useIonAlert(); 

  const [firstWarning, setFirstWarning] = useState<string>(''); 
  const [secondWarning, setSecondWarning] = useState<string>(''); 
  const [thirdWarning, setThirdWarning] = useState<string>(''); 
  const [expire, setExpire] = useState<string>('');

  const convertSeconds = (seconds: number) => {

    const minutes = seconds % 60;


  }


  const convertTime = (time: string, setFunction: any) => {
    setFunction(time); 

    const dateFormat = new Date(time);

    const totalSeconds = ((dateFormat.getMinutes() * 60) + dateFormat.getSeconds());

    if (setFunction === setFirstWarning) {
      setValue("first_warning", totalSeconds, { shouldValidate: true});
    } else if (setFunction === setSecondWarning){
      setValue("second_warning", totalSeconds, { shouldValidate: true});
    } else if (setFunction === setThirdWarning){
      setValue("third_warning", totalSeconds, { shouldValidate: true}); 
    }

    console.log(totalSeconds); 


  }

  const onSubmit = async (data: any) => {
    console.log(data); 
    console.log("reach the on submit"); 
    
    const updateTaskTemplate: TaskTemplate = data;
    console.log(updateTaskTemplate);
    api.saveTaskTemplate(updateTaskTemplate); 
    setEditMode(false);


  }

  const onDismiss = () => {
    if (taskTemplate.id === undefined || editMode === true){
      dismissAlert({
        message: "Oops! Looks like you've pressed back! Are you sure you want to lose your new changes?",
        buttons: [
          'No',
          {text: 'Yes', handler: dismissTaskTemplate}
        ]
      })
    } else {
      dismissTaskTemplate()
    }
  }

	return (
    <IonPage>
    <IonToolbar color="tertiary">
        <IonTitle>{taskTemplate.title === '' ? 'Adding Template' :  'Task Template: ' + taskTemplate.title }</IonTitle>
        <IonButtons slot="start">
            <IonButton fill="clear" onClick={onDismiss}><IonIcon icon={chevronBackOutline}/> Back</IonButton>
        </IonButtons>
        <IonButtons slot="end">
          {editMode ? 
          <IonButton fill="clear" type="submit" onClick={() => {handleSubmit(onSubmit)()}}>Save</IonButton>
          :
          <IonButton fill="clear" onClick={() => setEditMode(true)}>Edit</IonButton>
          }
        </IonButtons>
    </IonToolbar>
    <IonContent className="page-bg">    
    <form>  
      <IonCard>
        <IonCardHeader>Template Information</IonCardHeader>
        <IonCardContent>
          <IonList>
            <IonItem color={errors.title ? 'danger' : ''}>
              <IonLabel><b>Title</b></IonLabel>
              {editMode? <IonInput slot="end" clearInput type="text" {...register("title", {required: true, minLength: 2})}></IonInput> : <IonText>{taskTemplate.title}</IonText>}
            </IonItem>
            <IonItem>
              <IonLabel><b>Notes</b></IonLabel>
              {editMode ? <IonTextarea clearOnEdit {...register("notes", {required: true})} /> : <IonText>{taskTemplate.notes} </IonText> }
            </IonItem>
            <IonItem>
              <IonLabel><b>Importance</b></IonLabel>
              {editMode ? <IonSelect {...register("importance", {required: true})}>
                <IonSelectOption value={0}>Urgent</IonSelectOption>
                <IonSelectOption value={1}>High</IonSelectOption>
                <IonSelectOption value={2}>Normal</IonSelectOption>
                <IonSelectOption value={3}>Low</IonSelectOption>
              </IonSelect> : <TaskPriority display="text" taskNumber={taskTemplate.importance} /> }
            </IonItem>
            <IonItem>
              <IonLabel><b>Photo Required</b></IonLabel>
              {editMode ? <IonSelect {...register("photo_required", {required: true})}>
                  <IonSelectOption value={true}>Yes</IonSelectOption>
                  <IonSelectOption value={false}>No</IonSelectOption>
                </IonSelect> :
                <IonText>{taskTemplate.photo_required ? 'Yes' : 'No'}</IonText> }
            </IonItem>
          </IonList>
        </IonCardContent>
      </IonCard>
      <IonCard>
        <IonCardHeader>Frequency</IonCardHeader>
        <IonCardContent>
          <IonList>
            <IonItem>
              <IonLabel><b>Next Start</b></IonLabel>
              {editMode ? 
              <IonDatetime 
              className='color-black' 
              placeholder="Select Date" 
              {...register("next_start")} 
              pickerFormat={"D MM YYYY H:mm"}
              displayFormat={"D/MM/YYYY H:mm"}
              min={ `${new Date().getFullYear()}` } 
              max={ `${new Date().getFullYear() + 1}` } 
              /> :
              <IonText>{taskTemplate.next_start}</IonText>

            }
            </IonItem>
            <IonItem>
              <IonLabel><b>Frequency</b></IonLabel>
              {editMode ? <IonSelect {...register("frequency", {required: true})}>
                  
                  <IonSelectOption value="15 mins"> Every 15 mins </IonSelectOption>
                  <IonSelectOption value="30 mins">Every 30 mins</IonSelectOption>
                  <IonSelectOption value="45 mins">Every 45 mins</IonSelectOption>
                  <IonSelectOption value="hourly">Hourly</IonSelectOption>
                  <IonSelectOption value="3 hour">Every 3 hours</IonSelectOption>
                  <IonSelectOption value="5 hours">Every 5 hours</IonSelectOption>
                  <IonSelectOption value="daily">daily</IonSelectOption>
                  <IonSelectOption value="weekly">Weekly</IonSelectOption>
                  <IonSelectOption value="monthly">Monthly</IonSelectOption>

                </IonSelect> : <IonText>{taskTemplate.frequency}</IonText> }
            </IonItem> 
          </IonList>

        </IonCardContent>
      </IonCard>
      <IonCard>
        <IonCardHeader>Time Limits</IonCardHeader>
        <IonCardContent>
          <IonList>
            <IonItem>
              <IonLabel><b>First Warning</b></IonLabel>
              {editMode ? 
              <IonDatetime displayFormat="mm:ss" pickerFormat="mm:ss" value={firstWarning} onIonChange={e => convertTime(e.detail.value!, setFirstWarning)} />
              : <IonText>{taskTemplate.first_warning}</IonText>}
            </IonItem>
            <IonItem>
              <IonLabel><b>Second Warning</b></IonLabel>
              {editMode ? <IonDatetime displayFormat="mm:ss" pickerFormat="mm:ss" value={secondWarning} onIonChange={e => convertTime(e.detail.value!, setSecondWarning)}/> : <IonText>{taskTemplate.second_warning}</IonText>}
            </IonItem>
            <IonItem>
              <IonLabel><b>Third Warning</b></IonLabel>
              {editMode ? <IonDatetime displayFormat="mm:ss" pickerFormat="mm:ss" value={thirdWarning} onIonChange={e => convertTime(e.detail.value!, setThirdWarning)}/> : <IonText>{taskTemplate.third_warning}</IonText>}
            </IonItem>
            <IonItem>
              <IonLabel><b>Expire</b></IonLabel>
              {editMode ? <IonDatetime displayFormat="mm:ss" pickerFormat="mm:ss" value={expire} onIonChange={e => convertTime(e.detail.value!, setExpire)} /> : <IonText>{taskTemplate.expire}</IonText>}
            </IonItem>
            
            
          </IonList>

        </IonCardContent>
      </IonCard>
      {
        taskTemplate.id === undefined ?
        
        <IonButton fill="default" expand="full" color="primary" onClick={handleSubmit(onSubmit)}>
          Create Template
        </IonButton>:
        <></>
      }
      </form>
    </IonContent>
    
    </IonPage>
	);
};

export default TaskTemplatePage;
