import React from 'react';
import { 
  IonBadge, IonInput, 
  IonItem, IonLabel, 
  IonSelect} from '@ionic/react';

import { Interface } from 'readline';

interface InputProps {
    inputText: any,
    error: any,
    register: any,
    type: string,
    specForm: any,
    placeholder: string | undefined,
    list: any | undefined,
    itemType: Interface | undefined
  }

export const CustomInput = ({inputText, error, register, specForm, type, placeholder, list, itemType}: InputProps) => {
    const fieldRef = React.useRef<HTMLInputElement>(null);

    const fieldRequired = () => {
        return <IonBadge color="danger"><IonLabel>Required</IonLabel></IonBadge>
      }

    const FormError: React.FC<{errors: any}> = ( props) => {
        const  errors = props.errors;
      
        const  items = Object.entries(errors).map(([key, value])  =>  {
            return  (
              <span key={key}> {value}</span>
            );
        });
      
        return (<>
            {items}
        </>);
      }

    React.useEffect(() => {
      if (error && fieldRef.current){fieldRef.current.scrollIntoView({behavior: "smooth",});}
    }, [error]);

    const inputSelector = () => {
      if (type === "text"){
        return <IonInput position="floating" slot="end" color='dark' type="text" defaultValue="" {...register}></IonInput>
      } else if (type === "tel"){
        return <IonInput defaultValue="" type="tel" {...register}></IonInput>
      } else if (type === "email"){
        return <IonInput defaultValue="" type="email" {...register}></IonInput>
      } else if (type === "select"){
        <IonSelect 
              defaultValue="" 
              placeholder={placeholder}
              {...register} 
              interface="popover"
            >
            {/* {list && list.map((item:typeof itemType, index:number) => (
              <IonSelectOption key={index} value={item.id}>{item.name}</IonSelectOption>
            ))} */}
            </IonSelect>
      }
    }
    
    return (
      <div ref={fieldRef}>
        <IonItem color='light'>
              <IonLabel color="dark"><b>{inputText}</b></IonLabel>
                {inputSelector()}
                {error && fieldRequired()}
                {specForm && <FormError errors={specForm}/>}
        </IonItem>
      </div>
    );
  };

export default CustomInput;