import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar,IonItem,IonLabel,IonBadge,useIonAlert,
  IonRefresher,IonRefresherContent ,IonButtons,IonButton,
  IonItemSliding,IonItemOptions,IonItemOption  } from '@ionic/react';
import Moment from 'react-moment';
import { RefresherEventDetail } from '@ionic/core';

import { useDateChecker, Useage } from "../camera";

import {Client} from './ClientPage'
import React from 'react';

import {Api} from '../services/api';
import HeaderContainer from '../components/HeaderContainer';

const ImageItem: React.FC<{
  useage: Useage;
  updateUseage: (useage: Useage) => Promise<boolean>;
}> = ({useage,updateUseage}) =>{
  const [present] = useIonAlert();
  const { inpast, soon, next12 } = useDateChecker(useage);

  const onDelete = async (reason: string) => {
    const deletedUseage = {
      ...useage, active: false, note: reason
    };

    const success = await updateUseage(deletedUseage);

    if(success === false){
      present('Sorry, Error deleting, Please try again and contact my master if still not working.', [{ text: 'Ok' }]);
    }
  };

  return (
    <IonItemSliding >
    <IonItemOptions side="start">
      <IonItemOption color="danger" expandable onClick={()=>onDelete('Disposed')}>
        Disposed
      </IonItemOption>
    </IonItemOptions>

    <IonItem color={inpast ? 'danger': soon ? 'warning' :'success'}>
    <IonLabel>
      {useage.title}
    </IonLabel>
    <IonBadge slot="end">
    {next12 ?
    <Moment fromNow>{useage.expires && useage.expires.substr(0, 19)}</Moment>:
    <Moment format="dddd LT" utc>{useage.expires}</Moment>}
    </IonBadge>
    </IonItem>

    <IonItemOptions side="end">
      <IonItemOption color="tertiary" expandable onClick={()=>onDelete('Consumed')}>
        Consumed
      </IonItemOption>
    </IonItemOptions>
  </IonItemSliding>
)};


const StatusPage: React.FC<{
  api: Api
}> = ({api}) => {
  const [present] = useIonAlert();

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    
    await api.getUseages();
    event.detail.complete();
  
  }

  const clearAll = () =>{
      present({
              header: 'Alert',
              message: 'Are you sure you would like to remove all products?',
              buttons: [
                'Cancel',
                { text: 'Yes', handler: () => api.clearUseages() },
              ]
            });
    
  };

  return (
    <IonPage>
      <IonHeader>
        <HeaderContainer title="Status"/>
        <IonToolbar>
        <IonTitle size="small" slot="start">
        <IonLabel>{api.client?.stores && api.client.stores.reduce((out, value) => { return (value.id === api.store_id) ? value.title : out } , {} as string)}
        &nbsp;::&nbsp;
        <Moment format="dddd LT"></Moment>
        </IonLabel>
    </IonTitle>
        <IonButtons slot="end">
        <IonButton onClick={() => clearAll() } >Clear All</IonButton>
      </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        {api.useages.map((useage, index) => (
          useage.active === true &&
          <ImageItem key={index} useage={useage} updateUseage={api.updateUseage}/>
          ))}

      </IonContent>
    </IonPage>
  );
};

export default StatusPage;
