import { useState, useEffect } from "react";
import axios from 'axios';
import { Storage } from '@ionic/storage';

import {Client} from '../pages/ClientPage';
import {Store} from '../pages/StorePage'
import {Product} from '../pages/ProductPage'

import Task, { TaskTemplate } from '../modal/Task';

import { CapturedImage, Useage } from "../camera";

const API = 'https://ddms4biz.daktech.dev/subway/api';


export interface StoreLookup{
  id: number,
  title: string,
}

export interface Api{
  authed: boolean;
  useages: Useage[];
  getUseages: any; 
  updateUseage: any;
  products: Product[];
  client: Client | undefined;
  onLogout: any;
  saveClient: any;
  saveTask: any;
  addImage: any;
  images: CapturedImage[];
  onDeleteImage: any;
  onSaveImage: any;
  agree: boolean;
  sendCode: any;
  sendEmail: any;
  clearAgree: any;
  saveStore: any;
  store_id: number | undefined;
  onChangeStore: any;
  saveProduct: any;
  unlocked: boolean; 
  setUnlocked: (state: boolean) => void;
  pin: string | undefined;
  setPin: (value: string) => void;
  error: boolean;
  message: string;
  unlock: any;
  lock: any;
  missing: Product[];
  copyFromList: StoreLookup[];
  copyFrom: any;
  loading: boolean;
  clearUseages: () => Promise<void>;
  tasks: Task[];
  getTasks: () => any;
  taskTemplates: TaskTemplate[];
  getTaskTemplates: () => any;
  saveTaskTemplate: any;
}

export const useServerStatus = () => {
  
  const [dt, setDate] = useState(new Date()); // Save the current date to be able to trigger an update
  const [useages, setUseages] = useState<Useage[]>([]);
  const [authed, setAuthed] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState<string>('');
  const [client, setClient] = useState<Client>();
  const [id, setId] = useState<string>();
  const [token, setToken] = useState<string>();
  const [pass, setPass] = useState<string>();
  const [products, setProducts] = useState<Product[]>([]);
  const [missing, setMissing] = useState<Product[]>([]);
  const [loading, setLoading] = useState(false);
  const [store_id, setStoreId] = useState<number>();
  const [pin, setPin] = useState<string>();
  const [unlocked, setUnlocked] = useState<boolean>(false);
  const [copyFromList, setCopyFrom] = useState<StoreLookup[]>([]);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [taskTemplates, setTaskTemplates] = useState<TaskTemplate[]>([]); 
  
  const [images, setImages] = useState<CapturedImage[]>([]);
  
  const [agree, setAgree] = useState(false);
  
  const store = new Storage();
  store.create();

  useEffect(() => {
    
    (async function getStorage() {
      //await store.create();
      console.log('Load from local storage');
     
      const tmpAgree = await store.get('agree');
      setAgree(tmpAgree ? tmpAgree : false);

      const tmpId:string = await store.get('id');
      setId(tmpId ? tmpId : undefined);

      const tmpToken:string = await store.get('token');
      setToken(tmpToken ? tmpToken : undefined);

      const tmpPass:string = await store.get('pass');
      setPass(tmpPass ? tmpPass : undefined);

      const tmpStoreId:number = await store.get('store_id');
      setStoreId(tmpStoreId);

      //On load get server status
      checkAuthed(tmpId, tmpToken, tmpPass);
    })();

  },[]);

  const onChangeStore = async (newStoreId:number | undefined) => {
    //need to clear usages
    setUseages([]);
    

    setStoreId(newStoreId);
    await store.set('store_id',newStoreId);

  };

  useEffect(() => {
    //When images changes - check if new and then process it
    if(images.length > 0){
      const newImage = images[0];//We put the new ones at the start
      //processOCR(newImage);
      if(newImage.uploaded === false && newImage.error === false){
        console.log('Image added / changed',newImage);
        sendImage(newImage);
      }
      
    }

  },[images]);

  useEffect(() => {
    //when usage updates look for missing

    //Set missing to equal all active products
    //setMissing();
    let tmpMissing = products.filter((product:Product)=>product.active);
    useages.forEach((useage:Useage)=>{
      
      const dt = new Date();
      const tz = dt.getTimezoneOffset() * 60000;//Convert tz offset to miliseconds
      const adjusted_expires = useage?.expires_timestamp ? useage.expires_timestamp * 1000 + tz : 0;
      const diff = adjusted_expires - Date.now(); // + for in future, - for in past
      
      if(diff >= 0){//If not expired remove one from missing
      
        tmpMissing = tmpMissing.map((product:Product)=>{
          if(product.title == useage.title){
            console.log('Found Match - Updating',product.min - 1);
            //if match found remove 1 from qty
            return {...product, min: product.min - 1 };
          }
          return product;
        });
      }

    });

    tmpMissing = tmpMissing.filter((product:Product)=>product.min > 0);

    setMissing(tmpMissing);
  },[useages]);

  //missing
  
    //Setup
    useEffect(() => {
      const timer = setInterval(() => { // Creates an interval which will update the current data every minute
      // This will trigger a rerender every component that uses the useDate hook.
      setDate(new Date());
    }, 60 * 1000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    }
    
  }, []);

  //When time changes get usages
  useEffect(() => {
    if(authed && store_id) getUseages();
  }, [dt,authed,store_id]);

  const getUseages = async () => {
    console.log("getUseages");
    setError(false);
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': JSON.stringify({bob: 'hi', id: id, token: token, pass: pass})
      }
      const response = await axios.get(`${API}/useages/${store_id}.json`, {
          headers: headers
        });
      console.log(response.data);
      if(response.data?.status === 200){
        //Update useages
        setUseages(response.data.useages);
      }else{
        console.log('Error getting useages');
        setError(true);
        setMessage(response.data.message?response.data.message:'Error getting useages');
      }
    } catch (error) {
      console.log('Error getting useages',error);
      setError(true);
      setMessage('Error getting useages');
      //Not online
    }
  };

  const getTaskTemplates = async () => {
    console.log("getTemplates"); 
    setError(false); 
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': JSON.stringify({bob: 'hi', id: id, token: token, pass:pass})
      }

      const response = await axios.get(`${API}/task-templates/index/${store_id}.json`, {
        headers:headers
      });

      console.log(response.data);
      if(response.data?.status === 200){
        //Update useages
        setTaskTemplates(response.data.task_templates);
      }else{
        console.log('Error getting tasks');
        setError(true);
        setMessage(response.data.message?response.data.message:'Error getting task templates');
      }

    } catch (error) {
      console.log('Error getting tasks',error);
      setError(true);
      setMessage('Error getting tasks');
    }
  }

  useEffect(() => {
      if(authed && store_id) getTaskTemplates();
  }, [dt,authed,store_id]);

  const saveTaskTemplate = async (taskTemplate: TaskTemplate ) => {

    console.log("reaching saveTaskTemplate");

    let tmpTaskTemplates: TaskTemplate[] = taskTemplates;

    if (taskTemplate.id !== undefined){
      tmpTaskTemplates = taskTemplates.map((item => item.id === taskTemplate.id ? taskTemplate : taskTemplate)); 
    } else {
      tmpTaskTemplates.push(taskTemplate); 
    }

    setTaskTemplates(tmpTaskTemplates);
    await store.set('templates',tmpTaskTemplates);
    console.log(taskTemplate);

    // try {
    //   const response: any = '';
    // //server code
    //   const updateTask = {...task, 
    //     useage: response.data.useage,
    //     uploaded: true,
    //     message: response.data.message,
    //     error: !(response.data.status && response.data.status === 200)
    //   };

      

    // } catch (error: any) {
    //   console.log("error");

    // }

  
  }


  const getTasks = async () => {
    console.log("getTasks");
    setError(false);
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': JSON.stringify({bob: 'hi', id: id, token: token, pass: pass})
      }
      const response = await axios.get(`${API}/tasks/index/${store_id}.json`, {
          headers: headers
        });
      console.log(response.data);
      if(response.data?.status === 200){
        //Update useages
        setTasks(response.data.tasks);
      }else{
        console.log('Error getting tasks');
        setError(true);
        setMessage(response.data.message?response.data.message:'Error getting tasks');
      }
    } catch (error) {
      console.log('Error getting tasks',error);
      setError(true);
      setMessage('Error getting tasks');
      //Not online
    }
  };

  const clearUseages = async () => {
    console.log("clearUseages");
    setError(false);
    setLoading(true);
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': JSON.stringify({bob: 'hi', id: id, token: token, pass: pass})
      }
      const response = await axios.get(`${API}/clear/${store_id}.json`, {
          headers: headers
        });

      console.log(response.data);
      if(response.data?.status === 200){
        //Update useages
        setUseages([]);
      }else{
        console.log('Error clearnig useages');
        setError(true);
        setMessage(response.data.message?response.data.message:'Error clearnig useages');
      }
    } catch (error) {
      console.log('Error clearnig useages',error);
      setError(true);
      setMessage('Error clearnig useages');
      //Not online
    }
    setLoading(false);
  };

  const updateUseage = async (useage:Useage ) => {
    console.log("updateUseage");
    setError(false);
    try {

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': JSON.stringify({bob: 'hi', id: id, token: token, pass: pass})
      }
      
      const response = await axios.post(`${API}/photos/${store_id}.json`, 
        {
          useage: useage
        }, {
          headers: headers
        });
      
      console.log(response.data);

      //if saved update local
      if(response.data.status === 200){
          const updatedUseage = response.data.useage;
          //Update usage
          setUseages(useages.map(use => use.id === updatedUseage.id? updatedUseage : use ));
          //Success
          return true;
      }else{
        setError(true);
        setMessage(response.data.message?response.data.message:'Error getting useages');
      }
      
    } catch (error) {
      console.log('Error',error);
      //Not online
      setError(true);
      setMessage('Error connecting to server');
    }

    return false;
  };

  useEffect(() => {
    console.log("useEffect on auth");
    if(authed === true && products.length == 0 && store_id !== undefined){
      //if authed, get products
      getProducts();
      //
      getLookupStores();
    }

  },[authed,store_id]);

  useEffect(() => {
    if(client){
      
      //If has stores- make sure store id is valid
      if(client?.stores){
        //check if currently set store is active in stores
        //If not set to first element that is active
        if(!client.stores.some((element)=> element.active === true && element.id === store_id)){
          
          const firstActive = client.stores.find(element => element.active);
          const newStoreID = firstActive? firstActive.id : undefined;
          
          onChangeStore(newStoreID);
        }
      }else{
        onChangeStore(undefined);
      }
    }
  },[client]);

  /*
  useEffect(() => {
    if(pin){//On pin set check if good
      unlock(pin);
    }else{//Else set to locked
      setUnlocked(true);
    }
  },[pin]);*/

  const onSetClient = async (client:Client | undefined) => {
    setClient(client);
  }

  const loginClient = async (client:Client | undefined) => {
    onSetClient(client);
    if(client){
      
      setUnlocked(!client.haspin);
      setAuthed(true);
    }else{
      setAuthed(false);
    }
    return client;
  };

  const checkAuthed = async (tmpId:string, tmpToken:string, tmpPass:string) => {
    console.log("onTest");
    setLoading(true);
    setError(false);
    try {
      const response = await fetch(`${API}/auth.json`,{
        headers: {
          'Content-Type': 'application/json',
          'Authorization': JSON.stringify({bob: 'Hi', id: tmpId, token: tmpToken, pass: tmpPass})
        }
      });
      
      if (!response.ok) { 
        throw Error(response.statusText);
      }
      var data = await response.json();

      if(data?.products){
        console.log('Setting Products');
        setProducts(data.products);
      }

      if(data?.client?.id){
        setAuthed(true);
        loginClient(data.client);
      }else{
        setAuthed(false);
        loginClient(undefined);
      }
      
    } catch (error) {
      console.log(error);
      //Not online
      setError(true);
      setMessage('Error connecting to server');
      setLoading(false);
      return false;
    }
  
    setLoading(false);
  };

  const lock = () => {
    setPin('');
    setUnlocked(false);
  }

  const unlock = async (newPin: string) => {
    console.log("unlock",newPin);
    setLoading(true);
    setError(false);
    try {

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': JSON.stringify({bob: 'hi', id: id, token: token, pass: pass})
      }
      
      const response = await axios.post(`${API}/clients/check.json`, 
        {
          pin: newPin
        }, {
          headers: headers
        });

    
      if(response.data?.status == 200){
        console.log('Pin Good');
        setPin(newPin);
        setUnlocked(true);
        setLoading(false);
        return true;
      }else{
        setMessage( response.data?.message ? response.data?.message:'Invalid Pin');
        setError(true);
      }

    } catch (error) {
      console.log(error);
      //Not online
      setMessage('Error connecting to server');
      setError(true);
      setLoading(false);
      return false;
    }
  
    setLoading(false);
    return false;
  };

  const saveClient = async (client:Client) => {
    if(!client) return false;
    setLoading(true);
    setError(false);

    try {
      let url = API + '/clients/' + client.id;
      
      url += '.json';
      console.log(authed);
      console.log(url);
      console.log(client);

      const response = await fetch(url,{
        method: 'PUT',
        body: JSON.stringify(client),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': JSON.stringify({bob: 'hi', id: id, token: token, pass: pass})
        }
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }

      var data = await response.json();
      console.log(data);
      if(data?.client?.id){
        setAuthed(true);
        onSetClient(data.client);
      }else{
        setMessage(data.message ? data.message : 'Error connecting to server');
        setError(true);
      }
      setLoading(false);
      return data;//Data will contain any save errors

    } catch (error) {
      console.log(error);
      setLoading(false);
      setMessage('Error connecting to server');
      setError(true);
      return false;
    }
  };


  const saveStore = async (store:Store) => {
    if(!store) return false;
    setLoading(true);
    setError(false);
    try {

      let url = API + '/stores.json';
      
      const response = await fetch(url,{
        method: 'PUT',
        body: JSON.stringify(store),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': JSON.stringify({bob: 'hi', id: id, token: token, pass: pass})
        }
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }

      var data = await response.json();
      console.log(data);
      
      if(data?.status == 200 && data?.client?.id){
        onSetClient(data.client);
      }else{
        setMessage( data.message ? data.message : 'Error connecting to server');
        setError(true);
      }

      setLoading(false);
      return data;//Data will contain any save errors

    } catch (error) {
      console.log(error);
      setMessage('Error connecting to server');
      setError(true);
      setLoading(false);
      return false;
    }
  };

  const saveProduct = async (product:Product) => {
    if(!product || !store_id) return false;
    setLoading(true);
    setError(false);
    try {
      let url = API + '/products/';
      
      if(product.id){
        
        url += 'edit/' + product.id + '.json';
      }else{
        
        url += 'add/' + store_id + '.json';
      }
      
      const response = await fetch(url,{
        method: 'PUT',
        body: JSON.stringify(product),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': JSON.stringify({bob: 'hi', id: id, token: token, pass: pass})
        }
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }

      var data = await response.json();
      console.log(data);
      
      
      if(data?.status == 200 && data?.product?.id){
        //On success
        const savedProduct : Product = data?.product;
        //If update replace from current list
        if(product.id){
          setProducts( products.map(obj => obj.id === product.id ? savedProduct : obj ) );
        }else{//if add, add to local list
          setProducts( [...products, savedProduct] );
        }
        
      }else{
        setMessage(data?.message ? data.message: 'Error saving');
        setError(true);
      }

      setLoading(false);
      return data;//Data will contain any save errors

    } catch (error) {
      console.log(error);
      setMessage('Error connecting to server');
      setError(true);
      setLoading(false);
      return false;
    }
  };

  //Background - so no loading
  const getLookupStores = async () => {
    console.log("getLookupStores");
    setError(false);
    
    if(!store_id) return false;
    try {

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': JSON.stringify({bob: 'hi', id: id, token: token, pass: pass})
      }
      
      const response = await axios.get(`${API}/products/copy/${store_id}.json`, {
          headers: headers
        });
      
      console.log(response.data);
      if(response.data?.status === 200){
        //Update products
        setCopyFrom(response.data.stores);
      }else{
        console.log('Error getting lookup stores');
        setMessage('Error getting lookup stores');
        setError(true);
      }
      
    } catch (error) {
      console.log('Error getting lookup stores',error);
      //Not online
      setMessage('Error connecting to server');
      setError(true);
    }
  
  };

  //Will copy products from one store to another
  const copyFrom = async (store: number) => {
    console.log("getLookupStores");
    setError(false);
    setLoading(true);
    
    if(!store_id) return false;
    try {

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': JSON.stringify({bob: 'hi', id: id, token: token, pass: pass})
      }
      
      const response = await axios.get(`${API}/products/copy/${store_id}.json?from=${store}`, {
          headers: headers
        });
      
      console.log(response.data);
      if(response.data?.status === 200){
        //Update products
        setCopyFrom(response.data.stores);
        setProducts(response.data.products);
        setLoading(false);
        return true;
      }else{
        console.log('Error getting lookup stores');
        setMessage('Error getting lookup stores');
        setError(true);
      }
      
    } catch (error) {
      console.log('Error getting lookup stores',error);
      //Not online
      setMessage('Error connecting to server');
      setError(true);
    }
  
    setLoading(false);
    return false;
  };


  
  //Background update
  const getProducts = async () => {
    console.log("getProducts");
    setError(false);
    
    if(!store_id) return false;
    try {

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': JSON.stringify({bob: 'hi', id: id, token: token, pass: pass})
      }
      
      const response = await axios.get(`${API}/products/index/${store_id}.json`, {
          headers: headers
        });
      
      console.log(response.data);
      if(response.data?.status === 200){
        //Update products
        setProducts(response.data.products);
      }else{
        console.log('Error getting products');
        setMessage('Error getting products');
        setError(true);
      }
      
    } catch (error) {
      console.log('Error getting products',error);
      //Not online
      setMessage('Error connecting to server');
      setError(true);
    }
  
  };

  //Send in background
  const sendImage = async (image:CapturedImage ) => {
    console.log("sendImage");
    
    try {

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': JSON.stringify({bob: 'hi', id: id, token: token, pass: pass})
      }
      
      const response = await axios.post(`${API}/photos/${store_id}.json`, 
        {
          image: image.base64
        }, {
          headers: headers
        });
      
      console.log(response.data);

      const updateImage = {...image, 
        useage: response.data.useage,
        uploaded: true,
        message: response.data.message,
        error: !(response.data.status && response.data.status === 200)
      };

      //Update images
      setImages(images.map(img => img.id === updateImage.id? updateImage : img ));
      
    } catch (error: any) {
      console.log('Error',error);
      //Not online

      const updateImage = {...image, 
        uploaded: false,
        error: true,
        message: error.message
      };

      //Update images
      setImages(images.map(img => img.id === updateImage.id ? updateImage : img ));
      
    }
  
  };

  //
  const saveTask = async (task: Task ) => {

    console.log("reaching saveTask");


    setTasks(tasks.map((item => item.id === task.id ? task : task )));
    console.log(task);

    // try {
    //   const response: any = '';
    // //server code
    //   const updateTask = {...task, 
    //     useage: response.data.useage,
    //     uploaded: true,
    //     message: response.data.message,
    //     error: !(response.data.status && response.data.status === 200)
    //   };

      

    // } catch (error: any) {
    //   console.log("error");

    // }

  
  }
  const updateImage = async (image:CapturedImage ) => {
    console.log("updateImage");
    setLoading(true);
    try {

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': JSON.stringify({bob: 'hi', id: id, token: token, pass: pass})
      }
      
      const response = await axios.post(`${API}/photos/${store_id}.json`, 
        {
          useage: image.useage
        }, {
          headers: headers
        });
      
      //console.log(response.data);

      const updateImage = {...image, 
        useage: response.data.useage,
        uploaded: true,
        message: response.data.message,
        error: !(response.data.status && response.data.status === 200)
      };

      //Update images
      setImages(images.map(img => img.id === updateImage.id? updateImage : img ));
      
    } catch (error: any) {
      console.log('Error',error);
      //Not online

      const updateImage = {...image, 
        uploaded: false,
        error: true,
        message: error.message
      };

      //Update images
      setImages(images.map(img => img.id === updateImage.id ? updateImage : img ));
      
    }
    setLoading(false);
  }

  const addImage = (base64:string) => {

    const newImage = {
      id: new Date().getTime(),
      lat: 0,
      lng: 0,
      base64: base64,
      useage: undefined,
      uploaded: false,
      error: false,
      message: ''
    };

    setImages([ newImage,...images ]);
  }
  
    
  
  const clearAgree = async () => {
    setAgree(true);
    //await store.create();
    await store.set('agree',true);
    return true;
  };
  
    
  
  const onDeleteImage = (image:CapturedImage) => {
    //TODO: Tell server to archive image with reason. User Delete

    setImages(images.filter((obj) => { 
          return obj.id !== image.id
      }));
      
    return true;
  };
  
  const onSaveImage = async (image:CapturedImage) => {
    
    await updateImage(image);
    //Update local images
    //setImages(images.map(img => img.id === image.id ? image : img ));
    return image;
  };
  
  const onLogout = async () => {
      console.log("onLogout");
      setLoading(true);
      try {
        const response = await fetch(`${API}/auth/0.json`,{
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': JSON.stringify({bob: 'hi', id: id, token: token, pass: pass})
          }
        });
        if (!response.ok) {
          
          throw Error(response.statusText);
        }
        var data = await response.json();
        if(data?.status == 200){
          setAuthed(false);
          loginClient(undefined);
          onChangeStore(undefined);
          setLoading(false);
          return true;
        }
      } catch (error) {
        console.log(error);
        
      }
  
      setLoading(false);
      return false;
    };
  
    const sendEmail = async (email: string) => {
      setLoading(true);
      try {
        const response = await fetch(`${API}/auth.json`,{
          method: 'POST',
          body: JSON.stringify({email: email}),
          headers: {
            'Content-Type': 'application/json'
          },
        });
        if (!response.ok) {
          throw Error(response.statusText);
        }
        var data = await response.json();
        if(data?.status == 200 && data.id && data.token){
          setId(data.id);
          await store.set('id',data.id);
          setToken(data.token);
          await store.set('token',data.token);
          setLoading(false);
          return true;
        }
      } catch (error) {
        console.log(error);
        
      }
  
      setLoading(false);
      return false;
    };
  
    const sendCode = async (code: string) => {
      setLoading(true);
      try {
        const response = await fetch(`${API}/auth.json`,{
          method: 'POST',
          body: JSON.stringify(
            {
              code: code,
              id: id,
              token: token
            }
            ),
          headers: {
            'Content-Type': 'application/json'
          },
        });
        if (!response.ok) {
          throw Error(response.statusText);
        }
  
        var data = await response.json();
        console.log(data);
        if(data?.client?.id){
          setId(data.id);
          await store.set('id',data.id);
          setToken(data.token);
          await store.set('token',data.token);
          setPass(data.pass);
          await store.set('pass',data.pass);
  
          loginClient(data.client);
          setLoading(false);
          return true;
        }
  
      } catch (error) {
        console.log(error);
      }
  
      setLoading(false);
      return false;
    };
  
  const api: Api = {
    authed,
    useages, 
    getUseages, 
    updateUseage,
    products,
    client,
    onLogout,
    saveClient,
    addImage,
    images,
    onDeleteImage,
    onSaveImage,
    agree,
    sendCode,
    sendEmail,
    clearAgree,
    saveStore,
    saveTask,
    store_id, 
    onChangeStore,
    saveProduct,
    unlocked,
    setUnlocked,
    pin, 
    setPin,
    error,
    message,
    unlock,
    lock,
    missing,
    copyFromList,
    copyFrom,
    loading,
    clearUseages,
    tasks,
    getTasks,
    taskTemplates,
    getTaskTemplates,
    saveTaskTemplate
  }

  //In future this can return all types
  return api;
};


export default useServerStatus;