import React from 'react';
import { IonContent,IonCard,IonCardHeader,IonCardTitle,IonCardContent, IonPage, IonButton } from '@ionic/react';
import { RouteComponentProps } from 'react-router';


interface AgreePageProps extends RouteComponentProps { 
  clearAgree: any
}

const AgreePage: React.FC<AgreePageProps> = ({match, history, clearAgree}) => {
  return (
    <IonPage>
      <IonContent className="page-bg">
      <IonCard>
          <IonCardHeader className="ion-text-center">
            <IonCardTitle>Terms and Conditions</IonCardTitle>
            <IonCardContent>
            Blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah 
            Blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah 
          </IonCardContent>
          </IonCardHeader>
          <IonButton expand="block" onClick={() => clearAgree()} className="ion-margin-top">
              I Agree
        </IonButton>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default AgreePage;
