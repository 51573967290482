import React, { useState, useEffect } from 'react';
import { IonCardTitle, IonCardHeader, IonCard, 
  IonBadge, useIonModal, IonContent, IonPage, IonInput, 
  IonItem, IonLabel, IonList, IonButton ,IonListHeader, 
  IonSelect, IonSelectOption,useIonAlert ,IonIcon} from '@ionic/react';

import { RouteComponentProps } from 'react-router';
import StorePage,{Store} from './StorePage';
import {Client} from './ClientPage';
import HeaderContainer from '../components/HeaderContainer';
import { closeCircleOutline, syncCircleOutline } from 'ionicons/icons';

const StoreItem: React.FC<{
  store: Store | undefined;
  saveStore: any;
  store_id: number;
  setStoreId: any;
}> = ({store, saveStore, store_id, setStoreId}) =>{

const handleDismiss = () => {
  dismiss();
};

const [alertPresent] = useIonAlert();

const [present, dismiss] = useIonModal(StorePage, {
  store: store,
  saveStore: saveStore,
  handleDismiss: handleDismiss
});

const setActive = () => {
  setStoreId(store?.id);
};

const removeStore = async () => {
  const responce = await saveStore({...store, active: false});

  console.log(responce)
  if(responce?.status == 200){
    handleDismiss();
  }else{
    let $message = responce.message ? responce.message : 'Error saving';
    alertPresent($message, [{ text: 'Ok' }]);
  }
}

const restoreStore = async () => {
  const responce = await saveStore({...store, active: true});

  console.log(responce)
  if(responce?.status == 200){
    handleDismiss();
  }else{
    let $message = responce.message ? responce.message : 'Error saving';
    alertPresent($message, [{ text: 'Ok' }]);
  }
}

return store !== undefined ? (
  <IonItem color={store.active === true? store_id === store.id? 'success' :'' :'danger'}>
    <IonLabel onClick={() => store.id && present()}>{store.title}</IonLabel>
    {store.active === true && store_id !== store.id &&
    <><IonButton color="success" size="small" fill="clear" onClick={() => setActive()}>
        Set Active
    </IonButton>
    <IonButton color="danger" size="small" fill="clear" onClick={() => removeStore()}>
      <IonIcon icon={closeCircleOutline}/>
    </IonButton>
  </>
    }
    {store.active === false &&
    <IonButton color="success" size="small" onClick={() => restoreStore()}>
    Restore
    </IonButton>}

  </IonItem>
):(<>Empty</>)};


interface StoresPageProps extends RouteComponentProps { 
  saveStore: any,
  store_id: any,
  setStoreId: any,
  client: Client,
}
const StoresPage: React.FC<StoresPageProps> = ({ 
  history, 
  match, 
  client,
  saveStore,
  store_id,
  setStoreId }) => {
  const [presentAlert] = useIonAlert();
  const [showDeleted, setShowDeleted] = useState<boolean>();

  const [present, dismiss] = useIonModal(StorePage, {
    store: undefined,
    saveStore: saveStore,
    store_id: store_id,
    setStoreId: setStoreId,
    handleDismiss: () => { dismiss(); }
  });

  const addStore = () => {
    present();
  };

  return (
    <IonPage>
      
      <IonContent className="page-bg">
        <HeaderContainer title='Stores' />
      
      <IonCard>
     
        <IonList>
        <IonListHeader>
          <IonLabel>Stores</IonLabel>
          <IonButton onClick={() => addStore()}>Add Store</IonButton>
        </IonListHeader>
        {client?.stores !== undefined && client.stores.map((store: Store, index:number) => (
          (store.active === true || showDeleted === true ) && (
            <StoreItem key={index} 
            store={store} 
            saveStore={saveStore} 
            store_id={store_id}
            setStoreId={setStoreId}
            />
            )
        )
        )}
        </IonList>
        {showDeleted ? <IonButton fill="clear" size="small" expand="full" onClick={() => setShowDeleted(false)}>Show Active</IonButton> : <IonButton fill="clear" size="small" expand="full"  onClick={() => setShowDeleted(true)}>Show All</IonButton>}


        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default StoresPage;
