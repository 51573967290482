import React, { useState } from 'react';
import { IonCardTitle, IonBadge, IonCard, 
  useIonModal, IonContent, IonPage,  
  IonItem, IonLabel, IonList, IonButton ,IonListHeader, useIonLoading,
  useIonAlert, 
  IonRefresher,
  IonRefresherContent,
  IonTab,
  IonToolbar,
  IonTitle,
  IonIcon,
  IonGrid,
  IonRow,
  IonText} from '@ionic/react';
  import { RefresherEventDetail } from '@ionic/core';

import {Api} from '../services/api';
import { TaskTemplate, DefaultTemplateValues } from '../modal/Task';
import TaskTemplatePage from './TaskTemplatePage'; 
import HeaderContainer from '../components/HeaderContainer';
import { addCircleOutline } from 'ionicons/icons';


const TemplateItem: React.FC<{
  taskTemplate: TaskTemplate;
  api: Api;
}> = ({taskTemplate, api}) =>{

  const handleDismiss = () => {
    dismissTemplate();
  }
    
  const [presentTemplate, dismissTemplate] = useIonModal(TaskTemplatePage, {
    taskTemplate: taskTemplate,
    api: api,
    dismissTaskTemplate: handleDismiss})

  
  return (
    <IonItem button={true} onClick={() => presentTemplate()}>
      <IonLabel >{taskTemplate.title}</IonLabel>
    </IonItem>
)};

const TaskTemplatesPage: React.FC<{
  api: Api
  taskTemplates: TaskTemplate[]
}> = ({ api, taskTemplates}) => {

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    
    //await api.getTaskTemplates();
    event.detail.complete();
  
  }

  const handleDismiss = () => {
    dismissNewTemplate(); 
  }

  const emptyTemplate: TaskTemplate = DefaultTemplateValues;
  

  const [showNewTemplate, dismissNewTemplate] = useIonModal(TaskTemplatePage, {
    taskTemplate: emptyTemplate,
    api: api,
    dismissTaskTemplate: handleDismiss
  })
  return (
    <IonPage>
      <HeaderContainer title="Task Templates"/>
      <IonContent className="page-bg">
      <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      <IonList className="ion-margin-horizontal">
        <IonListHeader><IonText>Task Templates</IonText></IonListHeader>
        
        {taskTemplates.map((taskTemplate: TaskTemplate, index:number) => (
            <TemplateItem key={index} 
            taskTemplate={taskTemplate} api={api}/>
        ))}
        <IonButton onClick={() => showNewTemplate()}  expand="full" color="tertiary" fill="clear">Add Template</IonButton>
      </IonList>
      
      </IonContent>
    </IonPage>
  );
};

export default TaskTemplatesPage;
