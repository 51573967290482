import React, { useState } from 'react';
import { IonCardTitle, IonCardHeader, IonCard, 
  IonBadge, IonText, IonContent, IonPage, IonInput, 
  IonItem, IonLabel, IonList, IonButton ,IonItemDivider, 
  IonSelect, IonSelectOption,useIonAlert ,IonIcon, IonHeader} from '@ionic/react';

import { useForm } from "react-hook-form";

import CustomInput from '../components/InputContainer'
import HeaderContainer from '../components/HeaderContainer';

export interface Store {
  id: number | undefined,
  title: string,
  address: string,
  code: string,
  active: boolean,  
}

interface StoreErrors {
  title: string,
  address: string,
  code: string,
}

const StorePage: React.FC<{
  saveStore: any;
  store: Store | undefined;
  handleDismiss: any;
  store_id: number,
  setStoreId: any,
}> = ({ saveStore, store, handleDismiss, store_id, setStoreId}) => {
  const [present] = useIonAlert();
  
  const defaultValues:Store = store || {
    id:  undefined,
    title: '',
    address: '',
    code: '',
    active: true,  
  };
  
  const [formErrors, setFormErrors] = useState<StoreErrors>();

  const { register, handleSubmit, formState: { errors } } = useForm({defaultValues});

  const onSubmit = async (data: Store) => {
    //SaveClient
    
    const responce = await saveStore(data);

    console.log(responce)
    if(responce?.status == 200){
      handleDismiss();
    }else{
      let $message = responce.message ? responce.message : 'Error saving, please check inputs and try again';
      present($message, [{ text: 'Ok' }]);
      setFormErrors(responce.errors);
    }
  };

  return (
    <IonPage>
      
      <IonContent className="page-bg">

        <HeaderContainer title="Stores"/>



      
      <form onSubmit={handleSubmit(onSubmit)}>
      <IonCard>
      <IonCardHeader>
            <IonCardTitle>Store</IonCardTitle>
          </IonCardHeader>
      <input type="hidden" {...register("id")} />
        <IonList>
        
          {/* title */}
          <CustomInput type="text" 
            specForm={formErrors?.title} 
            inputText="Name" 
            error={errors.title} 
            register={register("title", { required: true })} 
            placeholder={undefined} 
            list={undefined} 
            itemType={undefined}></CustomInput>

          {/* Address */}
          <CustomInput type="text" 
            specForm={formErrors?.address} 
            inputText="Address" 
            error={errors.address} 
            register={register("address")} 
            placeholder={undefined} 
            list={undefined} 
            itemType={undefined}></CustomInput>

          {/* code */}
          <CustomInput type="text" 
            specForm={formErrors?.code} 
            inputText="Store Code" 
            error={errors.code} 
            register={register("code")} 
            placeholder={undefined} 
            list={undefined} 
            itemType={undefined}></CustomInput>

        </IonList>

        
        </IonCard>
        <IonButton expand="block" type="submit" className="ion-margin-top ion-padding-horizontal">
              Save
            </IonButton>
        </form>

        <IonButton expand="block" color="primary" className="ion-margin-top ion-padding-horizontal" onClick={()=>handleDismiss()}>
              Cancel
        </IonButton>
        
      </IonContent>
    </IonPage>
  );
};

export default StorePage;
