import { IonContent, IonPage, IonList, IonItem, IonLabel,IonButton,IonToolbar,IonTitle,IonButtons,IonBackButton,IonIcon, IonChip, IonAlert } from '@ionic/react';
import './HeaderContainer.css';
import { arrowBackOutline, cloudOfflineOutline} from 'ionicons/icons';
import { useEffect, useState } from 'react';
import useServerConnection, {Api} from "../services/api"

interface ContainerProps { 
  title: string, 
  hideBack?: boolean,
  backCallback?: any,
  api?: Api
}

const HeaderContainer: React.FC<ContainerProps> = ({title, api, hideBack, backCallback}) => {

  const myCustomIcon = "/assets/icon/65px.png";

//   {!hideBack && (
//     <IonButtons slot="start">
//       {backCallback ? <IonButton onClick={backCallback} ><IonIcon slot="icon-only" icon={arrowBackOutline}  /></IonButton> : <IonBackButton defaultHref="/" />}
//     </IonButtons>
//     )}

  return (
    <IonToolbar color="tertiary">
      
      <IonTitle>{title}</IonTitle>
      <IonIcon icon={myCustomIcon}></IonIcon>
    </IonToolbar>
  );
};

export default HeaderContainer;