import React, { useState } from "react";
import { 
  IonContent, 
  IonHeader, useIonAlert, IonText, 
  IonInput, IonPage, IonTitle,
  IonDatetime, IonToolbar, IonItem,
  IonLabel,IonBadge,IonSpinner,IonIcon,
  IonSelect,IonSelectOption,
  IonButton,useIonModal,IonList,
  IonGrid,IonRow,IonCol
} from '@ionic/react';
import { useForm } from "react-hook-form";
import { Camera, CapturedImage, Useage, useDateChecker } from "../camera";
import {alertCircleOutline, documentsOutline} from 'ionicons/icons';
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import Moment from 'react-moment';

import {Product} from './ProductPage'
import { Api } from "../services/api";
import HeaderContainer from "../components/HeaderContainer";

const Body: React.FC<{
  image: CapturedImage;
  products: Product[];
  onDismiss: () => void;
  onDelete: (image:CapturedImage) => boolean;
  onSave: (image:CapturedImage) => Promise<CapturedImage>;
}> = ({image, products, onDismiss, onDelete, onSave}) => {
  const [present] = useIonAlert();

  const deleteImage = () => {
    onDelete(image);

    onDismiss();
  };

  const onSubmit = async (data:Useage) => {
    console.log(data);

    const postImage = {...image,
      useage: {
        ...data, 
        expires: data.expires ? format(parseISO(data.expires),'yyyy-MM-dd HH:mm') : undefined
      }};
    console.log('Post Image',postImage);
    const savedImage = await onSave(postImage);
/*
    if(savedImage.error === false){
      onDismiss();
    }else{
      console.log('Error saving');
    }*/
    
    onDismiss();
  }

  const defaultValues:Useage = image.useage || {
    title: '',
    id: undefined,
    store_id: undefined,
    match: undefined,
    meta:  undefined,
    note:  undefined,
    expires:  undefined,
    preped:  undefined,
    replaced:  undefined,
    expires_timestamp:  undefined,
    valid:  undefined,
    text:  undefined,
    active:  undefined,
    created:  undefined,
    modified:  undefined
  };

  const { register, handleSubmit, formState: { errors } } = useForm({defaultValues});
  
  return (
  <div>
    <IonButton
          expand="block"
          color="danger"
          onClick={() =>
            present({
              header: 'Alert',
              message: 'Are you sure you would like to delete this image?',
              buttons: [
                'Cancel',
                { text: 'Ok', handler: (d) => deleteImage() },
              ],
              onDidDismiss: (e) => console.log('did dismiss'),
            })
          }
        >
          Delete
        </IonButton>

    {image.useage ? (
      <form onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" {...register("id")} />
    <IonList>
    <IonItem color='light'>
      <IonLabel color="dark">Product</IonLabel>
      
      {products.length > 0 ?
      <IonSelect placeholder="Select Product" {...register("title")}>
        {products.map((product,index) => product.active === true && <IonSelectOption key={index} value={product.title}>{product.title}</IonSelectOption>)}
    </IonSelect>
      :<IonInput color='dark' type="text" {...register("title")}></IonInput>}
      {errors.title && <IonBadge color="danger"><IonLabel>Required</IonLabel></IonBadge>}
    </IonItem>
    <IonItem color='light'>
      <IonLabel color="dark">Expires Time</IonLabel>
      <IonDatetime displayFormat="D MMM YYYY h:mm A" minuteValues="0,15,30,45" {...register("expires")}></IonDatetime>
      {errors.expires && <IonBadge color="danger"><IonLabel>Required</IonLabel></IonBadge>}
    </IonItem>
    <IonItem color='light'>
      <IonLabel color="dark">{image.useage.text ? 'Text Found' :'No Text Found'}</IonLabel>
      <IonText>{image.useage.text}</IonText>
    </IonItem>
    <IonItem>
    <ul>
      <li><b>meta</b> {image.useage.meta}</li>
      <li><b>valid</b> {image.useage.valid ? 'YES': 'NO'}</li>
    </ul>
    </IonItem>
    </IonList>
    <IonButton expand="block" type="submit" color="success" >
              Update
    </IonButton>
    </form>
    ) : (
      <span>No results from server</span>
    )}
    
    <IonButton expand="block" onClick={() => onDismiss()}>
      Close
    </IonButton>
  </div>
)};

const ImageItem: React.FC<{
    image: CapturedImage;
    products: Product[];
    onDeleteImage: (image:CapturedImage) => boolean;
    onSaveImage: (image:CapturedImage) => Promise<CapturedImage>;
  }> = ({image, products, onSaveImage, onDeleteImage}) =>{
  const handleDismiss = () => {
    dismiss();
  };

  const [present, dismiss] = useIonModal(Body, {
    image,
    products: products,
    onDismiss: handleDismiss,
    onSave: onSaveImage,
    onDelete: onDeleteImage
  });

  const { inpast, soon, next12 } = useDateChecker(image.useage);

  const getStatus = () => {
    //If error show danger
    if(image.error) return 'danger';

    //uploading - no colour
    if(image.uploaded === false) return '';
       
    //if OCR good
    if(image.useage?.valid === true) return 'success';

    //else has uploaded, not errored but also not found any text
    return 'warning';
  }

  return (
  <IonItem color={getStatus()} onClick={()=>{present()}}>
    <IonLabel>
    {image.uploaded === false && image.error === false && (<div className='inner'>
        <IonSpinner name="lines" color="primary"/>
      </div>)}
      {image.uploaded === false && image.error === true && (
        <IonIcon icon={alertCircleOutline} />
      )}
      {image.useage?.title ? image.useage.title : image.message}
    </IonLabel>
    {image.useage?.expires &&
    <IonBadge slot="end" color={inpast ? 'danger': soon ? 'warning' :'primary'}>
      {next12 ?
    <Moment fromNow>{image.useage?.expires}</Moment>:
   <Moment format="dddd LT" utc>{image.useage?.expires}</Moment>}
      </IonBadge>}
  </IonItem>
)};


const ScanPage: React.FC<{
  api: Api;
  addImage:any;
  images:CapturedImage[];
  products: Product[];
  onDeleteImage: (image:CapturedImage) => boolean;
  onSaveImage: (image:CapturedImage) => Promise<CapturedImage>;
}> = ({ api, addImage, images, products, onDeleteImage, onSaveImage }) => {
  const [showMissing, setShowMissing] = useState<boolean>(false);
  const [isCameraOpen, setIsCameraOpen] = useState(true);
  //const revimg = images.reverse();
  return (
    <IonPage>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <HeaderContainer title="Scan"/>
        </IonHeader>
        {isCameraOpen ? (<>
          <IonButton size="small" expand="full"  color="danger" onClick={() => setIsCameraOpen(false)}>Close Camera</IonButton>
          <Camera
            onCapture={ (base64:string) => addImage(base64)}
          /></>):(
            <IonButton size="small" expand="full" onClick={() => setIsCameraOpen(true)}>Open Camera</IonButton>
          )}
          
          <IonGrid>
          <IonRow>
          <IonCol style={{padding: 0}}><IonButton size="small" expand="full" disabled={!showMissing} onClick={() => setShowMissing(false)}>History
                  </IonButton></IonCol>
          <IonCol style={{padding: 0}}><IonButton size="small" expand="full" color={api.missing.length > 0 ? "danger" : "success"} disabled={showMissing} onClick={() => setShowMissing(true)}>Missing
            {api.missing.length > 0 && " #" + api.missing.length}
                </IonButton></IonCol>
          </IonRow>
          </IonGrid>
{showMissing ? 
  <IonList>
          {api.missing.map((product, index) => (product.active &&
            <IonItem key={index}>
            <IonLabel>{product.title} <IonBadge>{product.min}</IonBadge></IonLabel>
          </IonItem>
            ))}
        </IonList>
:
          <IonList>
          {images.map((image, index) => (
            <ImageItem key={index} 
              image={image} 
              products={products}
              onDeleteImage={onDeleteImage} 
              onSaveImage={onSaveImage} />
            ))}
        </IonList>}

      </IonContent>
    </IonPage>
  );
};

export default ScanPage;
