import React, { useState } from 'react';
import { IonCardTitle, IonBadge, IonCard, 
  useIonModal, IonContent, IonPage,  
  IonItem, IonLabel, IonList, IonButton ,IonListHeader, useIonLoading,
  useIonAlert, 
  IonIcon} from '@ionic/react';

import {Api} from '../services/api';

import { RouteComponentProps } from 'react-router';

import ProductPage, {Product} from './ProductPage';
import HeaderContainer from '../components/HeaderContainer';
import { closeCircleOutline } from 'ionicons/icons';

const ProductItem: React.FC<{
  product: Product | undefined;
  saveProduct: any;
  store_id: number;
}> = ({product, saveProduct, store_id}) =>{

const handleDismiss = () => {
  dismiss();
};

const [alertPresent] = useIonAlert();

const [present, dismiss] = useIonModal(ProductPage, {
  product: product,
  saveProduct: saveProduct,
  handleDismiss: handleDismiss
});

const removeProduct = async () => {
  const responce = await saveProduct({...product, active: false});

  console.log(responce)
  if(responce?.status == 200){
    handleDismiss();
  }else{
    let $message = responce.message ? responce.message : 'Error saving';
    alertPresent($message, [{ text: 'Ok' }]);
  }
}

const restoreProduct = async () => {
  const responce = await saveProduct({...product, active: true});

  console.log(responce)
  if(responce?.status == 200){
    handleDismiss();
  }else{
    let $message = responce.message ? responce.message : 'Error saving';
    alertPresent($message, [{ text: 'Ok' }]);
  }
}

return product !== undefined ? (
  <IonItem color={product.active === true? store_id === product.id? 'success' :'' :'danger'}>
    <IonLabel onClick={() => product.id && present()}>{product.title} </IonLabel><IonBadge>Min: {product.min}</IonBadge>
    {product.active === true && store_id !== product.id &&
    <IonButton color="danger" fill="clear" size="small" onClick={() => removeProduct()}>
      <IonIcon icon={closeCircleOutline} />
  </IonButton>
    }
    {product.active === false &&
    <IonButton color="success" size="small" onClick={() => restoreProduct()}>
    Restore
    </IonButton>}

  </IonItem>
):(<>Empty</>)};


const ImportFrom: React.FC<{
  api: Api;
  handleDismiss: any;
}> = ({api, handleDismiss}) =>{

  const [alertPresent] = useIonAlert();

  const importFrom = async (store_id:number) => {
    //isLoading();
    const result = await api.copyFrom(store_id);
    //loadingComplete();
    if(result){
      handleDismiss();
    }else{
      alertPresent("Error importing, please try again.", [{ text: 'Ok' }]);
    }
  };

return (
<IonPage>
      <IonContent className="page-bg">
      <IonCard>
        <IonList>
        <IonListHeader>
          <IonLabel>Import</IonLabel>
        </IonListHeader>
       {api.copyFromList && api.copyFromList.map( (store,index) =>
        <IonItem key={index}>
          <IonLabel>{store.title}</IonLabel>
          <IonButton color="success" size="small" onClick={() => importFrom(store.id)}>
          Import
        </IonButton>
        </IonItem>
      )}
        </IonList>
        </IonCard>
      </IonContent>
    </IonPage>)
};


interface ProductPageProps extends RouteComponentProps { 
  saveProduct: any,
  products: Product[] | undefined,
  store_id: any,
  api: Api
}

const ProductsPage: React.FC<ProductPageProps> = ({ history, match, saveProduct, products, store_id, api}) => {
  const [showDeleted, setShowDeleted] = useState<boolean>();

  const [present, dismiss] = useIonModal(ProductPage, {
    product: undefined,
    saveProduct: saveProduct,
    handleDismiss: () => { dismiss(); }
  });

  const [presentImport, dismissImport] = useIonModal(ImportFrom, {
    api: api,
    handleDismiss: () => { dismissImport(); }
  });

  const addStore = () => {
    present();
  };

  return (
    <IonPage>
      <IonContent className="page-bg">
        <HeaderContainer title="Products" />
      <IonCard>
        <IonList>
        <IonListHeader>
          <IonLabel>Products</IonLabel>
          <IonButton onClick={() => presentImport()}>Import</IonButton>
          <IonButton onClick={() => addStore()}>Add</IonButton>
        </IonListHeader>
        {products !== undefined && products.map((product: Product, index:number) => (
          (product.active === true || showDeleted === true ) && (
            <ProductItem key={index} 
            product={product} 
            saveProduct={saveProduct} 
            store_id={store_id}
            />
            )
        )
        )}
        </IonList>
        {showDeleted ? <IonButton fill="clear" size="small" expand="full" onClick={() => setShowDeleted(false)}>Show Active</IonButton> : <IonButton fill="clear" size="small" expand="full"  onClick={() => setShowDeleted(true)}>Show All</IonButton>}

        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default ProductsPage;
