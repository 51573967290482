import { IonBadge, IonText } from "@ionic/react";

const TaskPriority: React.FC<{taskNumber: number, display: string}> = ({taskNumber, display}) => {

    var message: string = '';
    var badgeColor: string = '';

    switch(taskNumber){
      case 0:
        message = 'Urgent';
        badgeColor = 'danger';
        break;
      case 1: 
        message = 'High';
        badgeColor = 'warning';
        break;
      case 2:
        message = 'Normal';
        badgeColor = 'default';
        break;
      case 3:
        message = 'Low';
        badgeColor = 'light';
        break;
    }

    if (display === "badge"){
        return (
            <IonBadge color={badgeColor} slot="start">{message}</IonBadge>
          )
    } else {
        return <IonText>{message}</IonText> 
    }
    
  }

  export default TaskPriority; 