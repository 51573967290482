
import { IonFab, IonFabButton, IonIcon, useIonLoading } from '@ionic/react';

import { useState, useRef, useEffect } from "react";
import Measure, { ContentRect } from "react-measure";
import { useCardRatio } from "../hooks/use-card-ratio";
import { useOffsets } from "../hooks/use-offsets";

import { camera } from 'ionicons/icons';

import {
  Video,
  Canvas,
  Wrapper,
  Container,
  Flash,
  Overlay,
  Button
} from "./styles";


export interface OCRLabel {
  product: string;
  match: number;
  unmatched: string[];
  expires_date: string;
  expires_day: string;
  expires_time: string;
  expires_timestamp: number;
  expires: string;
  good: boolean;
  text: string;
}

export interface Useage {
  id: Number | undefined;
  store_id: number | undefined;
  title: string;
  match: number | undefined;
  meta: string | undefined;
  note: string | undefined;
  expires: string | undefined;
  preped: string | undefined;
  replaced: string | undefined;
  expires_timestamp: number | undefined;
  valid: boolean | undefined;
  text: string | undefined;
  active: boolean | undefined;
  created: string | undefined;
  modified: string | undefined;
}

//https://blog.logrocket.com/responsive-camera-component-react-hooks/
export interface CapturedImage {
  id: Number;
  lat: Number;
  lng: Number;
  base64: string;
  useage: Useage | undefined;
  uploaded: boolean;
  error: boolean;
  message: string;
}

const CAPTURE_OPTIONS = {
  audio: false,
  video: { facingMode: "environment" }
};

interface CaptureOptions  {
    audio: boolean;
    video: { facingMode: string; };
};

export const useDateChecker = (useage: Useage | undefined) => {
  
  const [dt, setDate] = useState(new Date()); // Save the current date to be able to trigger an update

  useEffect(() => {
      const timer = setInterval(() => { // Creates an interval which will update the current data every minute
      // This will trigger a rerender every component that uses the useDate hook.
      setDate(new Date());
    }, 60 * 1000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    }
  }, []);

  var tz = dt.getTimezoneOffset() * 60000;//Convert tz offset to miliseconds
  const adjusted_expires = useage?.expires_timestamp ? useage.expires_timestamp * 1000 + tz : 0;
  const diff = adjusted_expires - Date.now(); // + for in future, - for in past

  const inpast = diff < 0;
  const soon =   !inpast && diff < 600000;//In the next 10 minutes
  const next12 = !inpast && diff < 43200000;//In the next 12 hours

  return {
    inpast,
    soon,
    next12,
  };
};
  
function useUserMedia(requestedMedia:CaptureOptions) {
    const [mediaStream, setMediaStream] = useState<MediaStream | undefined>(undefined);
    const [isLoading, loadingComplete] = useIonLoading();


    useEffect(() => {
      async function enableVideoStream() {
        isLoading();
        try {
          const stream = await navigator.mediaDevices.getUserMedia(
            requestedMedia
          );
          
          setMediaStream(stream);
        } catch (err) {
          // Handle the error
        }
        loadingComplete();
      }
  
      if (!mediaStream) {
        enableVideoStream();
      } else {
        return function cleanup() {
          mediaStream.getTracks().forEach( (track:any) => {
            track.stop();
          });
        };
      }
    }, [mediaStream, requestedMedia]);
  
    return mediaStream;
  }

export function Camera({ onCapture }:{
  onCapture:any;
}
  ) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const [container, setContainer] = useState({ width: 0, height: 0 });
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);
  const [isFlashing, setIsFlashing] = useState(false);

  const mediaStream = useUserMedia(CAPTURE_OPTIONS);
  const [aspectRatio, calculateRatio] = useCardRatio(1.586);
  const offsets = useOffsets(
    videoRef.current && videoRef.current.videoWidth,
    videoRef.current && videoRef.current.videoHeight,
    container.width,
    container.height
  );

  if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
    videoRef.current.srcObject = mediaStream;
  }

  function handleResize(contentRect:ContentRect) {
    contentRect.bounds && 
    setContainer({
      width: contentRect.bounds.width,
      height: Math.round(contentRect.bounds.width / aspectRatio)
    });
  }

  function handleCanPlay() {
    if(videoRef.current !== undefined && videoRef.current !== null){
      
      calculateRatio(videoRef.current.videoHeight, videoRef.current.videoWidth);
      setIsVideoPlaying(true);
      videoRef.current.play();
    }
    
  }

  function handleCapture() {
    if(canvasRef.current === undefined || videoRef.current === undefined || canvasRef.current === null || videoRef.current === null) return;

    const context = canvasRef.current.getContext("2d");

    if(context === null) return;

    context.drawImage(
      videoRef.current,
      offsets.x,
      offsets.y,
      container.width,
      container.height,
      0,
      0,
      container.width,
      container.height
    );

    //canvasRef.current.toBlob(blob => onCapture(blob), "image/jpeg", 1);
    onCapture(canvasRef.current.toDataURL('image/png', 1));
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    setIsCanvasEmpty(false);
    setIsFlashing(true);
  }

  /*
  function handleClear() {
    if(canvasRef.current === undefined || canvasRef.current === null) return;
    const context = canvasRef.current.getContext("2d");
    if(context === null) return;
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    setIsCanvasEmpty(true);
    onClear();
  }*/

  if (!mediaStream) {
    return null;
  }

  return (
    <Measure bounds onResize={handleResize}>
      {({ measureRef }) => (
        <Wrapper>
          <Container
            ref={measureRef}
            onClick={handleCapture}
            maxHeight={videoRef.current  && videoRef.current.videoHeight}
            maxWidth={videoRef.current  && videoRef.current.videoWidth}
            style={{
              height: `${container.height}px`
            }}
          >
            <Video
              ref={videoRef}
              hidden={!isVideoPlaying}
              onCanPlay={handleCanPlay}
              autoPlay
              playsInline
              muted
              style={{
                top: `-${offsets.y}px`,
                left: `-${offsets.x}px`
              }}
            />

            <Overlay hidden={!isVideoPlaying} />

            <Canvas
              ref={canvasRef}
              width={container.width}
              height={container.height}
            />

            <Flash
              flash={isFlashing}
              onAnimationEnd={() => setIsFlashing(false)}
            />
      {isVideoPlaying && (
        <IonFab vertical="bottom" horizontal="center" slot="fixed">
          <IonFabButton size="small">
            <IonIcon icon={camera} />
          </IonFabButton>
        </IonFab>)}
          </Container>
        </Wrapper>
      )}
    </Measure>
  );
}
