import React, { useState } from 'react';
import { IonCardTitle, IonBadge, IonCard, 
  useIonModal, IonContent, IonPage,  
  IonItem, IonLabel, IonList, IonButton ,IonListHeader, useIonLoading,
  useIonAlert, 
  IonRefresher,
  IonRefresherContent,
  IonTab,
  IonToolbar,
  IonTitle,
  IonIcon,
  IonGrid,
  IonRow,
  IonButtons} from '@ionic/react';
  import { RefresherEventDetail } from '@ionic/core';

import {Api} from '../services/api';

import Task from '../modal/Task';
import { testUserAgent } from '@ionic/core/dist/types/utils/platform';
import moment from 'moment';
import Moment from 'react-moment';
import { ta } from 'date-fns/locale';
import { min } from 'date-fns';
import TaskPage from './TaskPage';
import { dismiss } from '@ionic/core/dist/types/utils/overlays';
import { airplane } from 'ionicons/icons';
import TaskPriority from '../components/PriorityComponent';
import HeaderContainer from '../components/HeaderContainer';

const TaskItem: React.FC<{
  task: Task;
  api: Api;
}> = ({task, api}) =>{

  const dateTime = new Date(task.end);

  const hours = dateTime.getHours();
  const minutes = dateTime.getMinutes();

  const handleTask = () => {
    console.log("submitting");
    dismissTask();

    
  }

  const handleDismiss = () => {
    dismissTask();
  }

  const [presentTask, dismissTask] = useIonModal(TaskPage, {
    task,
    api, 
    updateTask: handleTask,
    dismissTask: handleDismiss
  });
  
  return (
    <IonItem button={true} onClick={() => presentTask()}>
      <TaskPriority display="badge" taskNumber={task.task_template.importance} />
      <IonLabel >{task.task_template.title}</IonLabel>
      <IonLabel slot="end"> Due: {hours}:{minutes}</IonLabel>
    </IonItem>
)};

const TasksPage: React.FC<{
  api: Api
}> = ({ api}) => {

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    
    await api.getTasks();
    event.detail.complete();
  
  }

  return (
    <IonPage>
      <HeaderContainer title={"Tasks"} />
      <IonContent className="page-bg">
      <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      <IonList className="ion-margin-horizontal">
        <IonListHeader>Tasks to be completed</IonListHeader>
        
        {api.tasks !== undefined && api.tasks.map((task: Task, index:number) => (
          (task.active === true) && (
            <TaskItem key={index} 
            task={task} api={api}
            />
            )
        )
        )}
      </IonList>
      </IonContent>
    </IonPage>
  );
};

export default TasksPage;
